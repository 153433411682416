import React,{Component} from 'react';
import {GallerySection,TopLayout,ButtonLayout,LeftBtn,RightBtn,
    HeadingLayout,SliderOuterWrapper,SliderWrapper,SubHeading,Heading,ImageHolder,Button
} from './gallery.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Gallery extends Component {
    
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }

    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
    
    render() {
        const settings = {
            arrows:false,
            dots:false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            rows:2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                  breakpoint: 575,
                  settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        rows:1
                    }
                }
            ]
        };
        return (
            <GallerySection id="gallerySection">
                <Container>
                    <Row>
                        <Col md={12}>
                            <TopLayout>
                                <HeadingLayout>
                                    <SubHeading>
                                        {this.props.GalleryData.SubHeading}
                                    </SubHeading>
                                    <Heading>
                                        {this.props.GalleryData.Heading}
                                    </Heading>
                                </HeadingLayout>

                                <ButtonLayout>
                                    <Button aria-label="Left Button" marginRight={"10px"} onClick={this.previous}>
                                        <LeftBtn />
                                    </Button>
                                    <Button aria-label="Right Button" onClick={this.next}>
                                        <RightBtn />
                                    </Button>
                                </ButtonLayout>
                            </TopLayout>
                        </Col>
                    </Row>
                    <SliderOuterWrapper>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.GalleryData.Images.map((imgObj, idx) => {
                                    return <SliderWrapper> 
                                        <ImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,idx)}>
                                            <GatsImg 
                                                fluid={imgObj.Image.childImageSharp.fluid} 
                                                alt=""
                                            />
                                        </ImageHolder>
                                    </SliderWrapper>
                                })
                            }
                        </Slider>
                    </SliderOuterWrapper>
                </Container>
            </GallerySection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`
        query {
            realestatePage1Json{
                Gallery{
                    SubHeading
                    Heading
                    PrevImg
                    NextImg
                    Images{
                        Image{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
    render={(data) => (
        <Gallery 
        GalleryData={data.realestatePage1Json.Gallery}
        {...props}
        />
    )}
/>
)

import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {OurStatsSection,
    OurStatsLayout,
    SingleStatLayout,
    StatImage,
    TextLayout,
    StatHeading,
    StatPara
} from './ourstats.style';

import { useStaticQuery, graphql } from 'gatsby';

const OurStats = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        realestatePage1Json{
            OurStats{
                OurProjectsHeading
                StatDetails{
                    StatImage
                    StatHeading
                    StatPara
                }

            }
        }
    }
  `);
  const OurStatsData = JSONData.realestatePage1Json.OurStats;

    return(
        <OurStatsSection id="statsSection">
            <Container>
                <OurStatsLayout>
                    <Row>
                        {
                            OurStatsData.StatDetails.map((statsItem, statsIdx) => {
                            return <Col lg={3} md={6} sm={6}>
                                <SingleStatLayout>
                                    <StatImage src={statsItem.StatImage} alt=""/>
                                    <TextLayout>
                                    <StatHeading>{statsItem.StatHeading}</StatHeading>
                                    <StatPara>{statsItem.StatPara}</StatPara>
                                    </TextLayout>
                                </SingleStatLayout>
                            </Col>
                            })
                        }
                    </Row>
                </OurStatsLayout>
            </Container>
        </OurStatsSection>

    );
}

export default OurStats;
import React,{Component}from 'react';
import {TestimonialSection,TestimonialWrapper,HeadingLayout,SubHeading,Heading,
    SliderOuterWrapper,SliderWrapper,TestimonialCard,ImageLayout,
    TextLayout,StyledIconOpen,TestimonialText,QuoteHolder,StyleIconClose,Author,Designation,
    VerticalDiv,Button,PrevImg,NextImg,NextPrev
} from './testimonials.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
            ]
        };

        return (
            <TestimonialSection id="testimonialsSection">
                <Container>
                    <TestimonialWrapper>
                        <Row>
                            <Col md={12}>
                                <HeadingLayout>
                                    <SubHeading>
                                    {this.props.TestimonialsData.SubHeading} 
                                    </SubHeading>
                                    <Heading>
                                    {this.props.TestimonialsData.Heading} 
                                    </Heading>
                                </HeadingLayout>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <SliderOuterWrapper>
                                    <Slider ref={c => (this.slider = c)} {...settings}>
                                    {
                                        this.props.TestimonialsData.TestimonialCards.map((item,idx) => {
                                        return <SliderWrapper>
                                            <TestimonialCard>
                                                <ImageLayout>
                                                    <GatsImg 
                                                        fluid={item.Img.childImageSharp.fluid} 
                                                        alt=""
                                                        className="authorImg"
                                                        imgStyle={{
                                                            objectPosition:"top"
                                                        }}
                                                    />
                                                    <Author>
                                                    {item.Author} 
                                                    </Author>
                                                    <Designation>
                                                    {item.Designation} 
                                                    </Designation>
                                                </ImageLayout>
                                                <VerticalDiv />
                                                <TextLayout>
                                                    <StyledIconOpen />
                                                    <TestimonialText>
                                                    {item.TestimonialText} 
                                                    </TestimonialText>
                                                    <QuoteHolder>
                                                    <StyleIconClose />
                                                    </QuoteHolder>
                                                </TextLayout>
                                            </TestimonialCard>
                                            </SliderWrapper>
                                        })
                                    }
                                    </Slider>
                                    <NextPrev>
                                        <Button onClick={this.previous} aria-label="Prev Button">
                                            <PrevImg src={this.props.TestimonialsData.PrevImg} alt=""/>
                                        </Button>
                                        <Button onClick={this.next} aria-label="Next Button">
                                            <NextImg src={this.props.TestimonialsData.NextImg} alt=""/>
                                        </Button>
                                    </NextPrev>
                                </SliderOuterWrapper>
                            </Col>
                        </Row>
                    </TestimonialWrapper>
                </Container>
            </TestimonialSection>
        );
    }
}
export default () => (
    <StaticQuery
        query={graphql`
            query {
                    realestatePage1Json{
                        Testimonials{
                        SubHeading
                        Heading
                        TestimonialCards{
                            Img{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            TestimonialText
                            Author
                            Designation
                        }
                        PrevImg
                        NextImg
                    }
                }
            }
            
        `}
        render={(data) => (
            <Testimonials TestimonialsData={data.realestatePage1Json.Testimonials}/>
        )}
    />
  )